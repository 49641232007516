<template>
  <v-dialog
    v-model="open"
    :width="component.cart.width"
    :fullscreen="component.cart.fullscreen"
    persistent
    scrollable
  >
    <v-card :class="classes" :style="styles + ';' + gradient">
      <v-btn
        v-if="component.cart.fullscreen === true"
        top
        right
        absolute
        small
        icon
        @click="handleClose"
        style="z-index: 100"
      >
        <v-icon :color="component.cart.title.color">clear</v-icon>
      </v-btn>

      <div v-if="this.processingOrder || !this.orderSuccess">
        <v-row no-gutters justify="space-between">
          <v-col>
            <view-text
              :settings="component.cart.cartTitle"
              :value="component.cart.cartTitle.value"
            ></view-text>
          </v-col>
          <v-col cols="auto">
            <view-text
              :settings="component.cart.points"
              value="Order Value:"
            ></view-text>
            <view-text
              :settings="component.cart.subheading"
              value="Points Balance:"
            ></view-text>
            <view-text
              :settings="component.cart.subheading"
              value="Points Remaining:"
            ></view-text>
          </v-col>
          <v-col cols="auto">
            <view-text
              :settings="component.cart.points"
              :value="cartValue | number"
            ></view-text>
            <view-text
              :settings="component.cart.subheading"
              :value="currentUser.balance | number"
            ></view-text>
            <view-text
              :settings="component.cart.subheading"
              :value="(currentUser.balance - cartValue) | number"
            ></view-text>
          </v-col>
        </v-row>
        <v-stepper
          flat
          class="stepper-style ma-0 pa-0"
          v-model="stage"
          vertical
        >
          <!----------------------------------------------->
          <v-stepper-step
            step="1"
            :complete="stage > 1"
            :color="component.cart.button.color.hex"
          >
            <v-row class="ml-0"
              ><view-text
                :settings="component.cart.stepperLabels"
                value="Order"
              ></view-text
            ></v-row>
            <v-row v-if="stage > 1" class="ml-0">
              <view-text
                v-for="(item, i) in cartTableDetail"
                :key="i"
                :settings="component.cart.stepperSummary"
                :value="
                  item.idx + 1 === cartTableDetail.length
                    ? item.quantity +
                      ' x ' +
                      item.title +
                      ' ' +
                      '(' +
                      formatPoints(item.subtotal) +
                      ')'
                    : item.quantity +
                      ' x ' +
                      item.title +
                      ' ' +
                      '(' +
                      formatPoints(item.subtotal) +
                      ')' +
                      ','
                "
              ></view-text>
            </v-row>
          </v-stepper-step>
          <v-stepper-content step="1">
            <!-- items list --->
            <v-card
              color="transparent"
              elevation="0"
              :style="detailTableStyles"
            >
              <v-data-table
                :headers="headers"
                :items="cartTableDetail"
                :item-key="idx"
                :search="search"
                :style="cssVars"
                class="table"
                hide-default-footer
                :page.sync="page"
                @page-count="pageCount = $event"
                :items-per-page="itemsPerPage"
                :loading="loadingPointsTable"
                :header-props="{ sortIcon: null }"
                :no-data-text="$t('No Items In Shopping Cart')"
                :footer-props="{
                  'items-per-page-text': $t('Rows per page') //Rows per page:
                }"
              >
                <template v-slot:[`item.image`]="{ item }">
                  <v-row align="center">
                    <v-col cols="auto">
                      <v-avatar
                        :size="component.cart.imageSize"
                        :tile="component.cart.imageTile"
                      >
                        <v-img
                          :src="
                            item.externalImageUrl
                              ? item.externalImageUrl
                              : item.image.smallUrl
                          "
                        />
                      </v-avatar>
                    </v-col>
                    <v-col>
                      <v-row no-gutters align="center">
                        {{ item.title | truncate(30) }}
                      </v-row>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.quantity`]="{ item }">
                  <v-row no-gutters align="center" justify="center">
                    <v-col cols="4">
                      <v-icon
                        @click="minus(item)"
                        color="red"
                        :size="component.cart.iconSize"
                        >remove_circle_outline</v-icon
                      >
                    </v-col>
                    <v-col cols="4">
                      {{ item.quantity }}
                    </v-col>
                    <v-col cols="4">
                      <v-icon
                        @click="plus(item)"
                        color="green"
                        :size="component.cart.iconSize"
                        >add_circle_outline</v-icon
                      >
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.created`]="{ item }">
                  {{ item.created }}
                </template>

                <template v-slot:[`item.each`]="{ item }">
                  {{ item.each | number }}
                </template>

                <template v-slot:[`item.subtotal`]="{ item }">
                  {{ item.subtotal | number }}
                </template>

                <template v-slot:[`item.points`]="{ item }">
                  {{ item.points | number }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    :size="component.cart.iconSize"
                    color="red"
                    medium
                    @click="handleDelete(item)"
                    >highlight_off</v-icon
                  >
                </template>
              </v-data-table>
              <v-row
                justify="center"
                v-if="pageCount > 1"
                no-gutters
                class="mt-2"
                align="center"
              >
                <v-btn
                  v-if="page > 1"
                  rounded
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="page--"
                >
                  <v-icon
                    :color="this.component.cart.content.heading.color"
                    size="20"
                    >chevron_left</v-icon
                  >
                </v-btn>
                <v-btn
                  v-for="page in pageCount"
                  :key="page"
                  x-small
                  elevation="0"
                  color="transparent"
                  rounded
                  @click="handlePage(page)"
                >
                  <span :style="cssVars" :class="paginationStyle(page)">
                    {{ page }}
                  </span>
                </v-btn>
                <v-btn
                  v-if="page < pageCount"
                  rounded
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="page++"
                >
                  <v-icon
                    :color="this.component.cart.content.heading.color"
                    size="20"
                    >chevron_right</v-icon
                  >
                </v-btn>
              </v-row>
            </v-card>
            <!-- items list --->
          </v-stepper-content>

          <v-stepper-step
            step="2"
            :complete="stage > 2"
            :color="component.cart.button.color.hex"
          >
            <v-row class="ml-0" justify="start"
              ><view-text
                :settings="component.cart.stepperLabels"
                value="Delivery Address"
              ></view-text
            ></v-row>
            <v-row
              v-if="stage > 2"
              class="ml-0 mt-3"
              justify="start"
              no-gutters
            >
              <v-col>
                <v-row justify="start" no-gutters
                  ><view-text
                    :settings="component.cart.stepperSummary"
                    :value="currentUser.firstname + ' ' + currentUser.lastname"
                  ></view-text>
                </v-row>
                <v-row justify="start" no-gutters>
                  <view-text
                    :settings="component.cart.stepperSummary"
                    :value="address"
                  ></view-text>
                </v-row>
                <v-row no-gutters justify="start">
                  <view-text
                    :settings="component.cart.stepperSummary"
                    :value="suburb"
                  ></view-text>
                  <view-text
                    :settings="component.cart.stepperSummary"
                    :value="selectedRegion"
                  ></view-text>
                  <view-text
                    :settings="component.cart.stepperSummary"
                    :value="selectedCountry"
                  ></view-text>
                  <view-text
                    :settings="component.cart.stepperSummary"
                    :value="postcode"
                  ></view-text>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-step>

          <v-stepper-content step="2">
            <!-- address --->
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-select
                v-model="selectedCountry"
                :items="countryList"
                background-color="white"
                dense
                outlined
                :label="$t('Country/Region')"
                required
                :rules="countryRules"
                class="mt-1"
              >
              </v-select>

              <v-text-field
                :disabled="addressFormDisabled"
                type="text"
                :label="$t('Address')"
                v-model="address"
                background-color="white"
                dense
                outlined
                id="address"
                required
                :rules="addressRules"
              ></v-text-field>

              <v-row>
                <v-col>
                  <v-text-field
                    :disabled="addressFormDisabled"
                    type="text"
                    :label="$t('Suburb/City')"
                    v-model="suburb"
                    background-color="white"
                    dense
                    outlined
                    id="suburb"
                    required
                    :rules="suburbRules"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    :disabled="addressFormDisabled"
                    v-model="selectedRegion"
                    :items="regionList"
                    background-color="white"
                    dense
                    outlined
                    :label="$t('State/Province')"
                    id="state"
                    required
                    :rules="stateRules"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    :disabled="addressFormDisabled"
                    id="postcode"
                    type="text"
                    :label="$t('Postcode/Zip')"
                    v-model="postcode"
                    background-color="white"
                    dense
                    outlined
                    required
                    :rules="postcodeRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <!-- address --->
          </v-stepper-content>

          <!-- submit --->

          <v-stepper-step
            step="3"
            :complete="stage > 3"
            :color="component.cart.button.color.hex"
          >
            <v-row class="ml-0"
              ><view-text
                :settings="component.cart.stepperLabels"
                value="Review and Submit"
              ></view-text
            ></v-row>
            <v-row no-gutters>
              <view-text
                v-if="stage === 3"
                class="mt-3"
                :settings="component.cart.stepperSummary"
                value="Ready to submit your order?"
              ></view-text>
            </v-row>
            <v-row no-gutters justify="start">
              <view-text
                v-if="stage === 3"
                :settings="component.cart.stepperSummary"
                value="Please check the order and delivery address."
              ></view-text>
            </v-row>
          </v-stepper-step>

          <v-stepper-content step="3" class="pt-0 mt-0">
            <!-- address --->

            <!-- address --->
          </v-stepper-content>

          <!-- submit --->
        </v-stepper>
        <v-row no-gutters justify="end">
          <v-btn
            :disabled="processingOrder"
            elevation="0"
            :color="component.cart.button.color.hex"
            :dark="!component.cart.button.dark"
            @click="handleClose()"
          >
            {{ $t("CLOSE") }}
          </v-btn>

          <v-btn
            v-show="stage > 1"
            :disabled="processingOrder"
            elevation="0"
            :color="component.cart.button.color.hex"
            @click="backStep()"
            :dark="!component.cart.button.dark"
          >
            {{ $t("BACK") }}
          </v-btn>

          <v-btn
            :disabled="checkoutBlock"
            elevation="0"
            :color="component.cart.button.color.hex"
            @click="nextStep()"
            :class="component.cart.button.dark ? '' : 'white--text'"
            :loading="processingOrder"
          >
            {{ $t(buttonText) }}
          </v-btn>
        </v-row>
      </div>

      <div v-else>
        <div>
          <v-row no-gutters class="px-12 pb-10" justify="center">
            <v-icon
              size="100"
              class="pt-12 pb-12"
              :color="component.cart.cartTitle.color"
              >fa-regular fa-circle-check</v-icon
            >
          </v-row>
          <v-row no-gutters class="px-12 pb-0" justify="center">
            <view-text
              :settings="component.cart.stepperLabels"
              :value="'Your order has been submitted successfully'"
            ></view-text>
          </v-row>
          <v-row no-gutters class="px-12 pb-12" justify="center">
            <view-text
              :settings="component.cart.stepperLabels"
              :value="'We have sent confirmation to your email address'"
            ></view-text>
          </v-row>
          <v-card-actions class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              :color="component.cart.button.color.hex"
              :dark="!component.cart.button.dark"
              @click="handleClose()"
            >
              {{ $t("CLOSE") }}
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ViewText from "../Text.vue";
import { COUNTRIES } from "@/constants/country";
import { DEFAULT_BANNER_URL } from "@/constants/email";
import { REPLY_TO_EMAIL } from "@/constants/email";
import numeral from "numeral";
import { nanoid } from "nanoid";

export default {
  created() {
    this.$store.dispatch("loadRewards");
    this.$store.dispatch("setProcessingOrder", false);
  },
  props: ["open", "member", "memberId", "component"],
  components: {
    ViewText
  },
  data: () => ({
    countries: COUNTRIES,
    countryShortlist: [
      "Australia",
      "Canada",
      "New Zealand",
      "South Africa",
      "United Kingdom",
      "United States"
    ],
    valid: true,
    closeCart: false,
    search: "",
    selectedTransactionId: "",
    cartNewOrEditOpen: false,
    cartDeleteOpen: false,
    notes: "",
    description: "",
    points: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    idx: "",
    address: "",
    address2: "",
    suburb: "",
    state: "",
    postcode: "",
    stage: 1,
    selectedCountry: "",
    selectedRegion: "",
    orderSuccess: false,

    // Table settings
    options: {
      itemsPerPage: 10,
      sortable: false,
      multiSort: false,
      search: false,
      filter: false,
      upload: false,
      download: false,
      more: false,
      new: false
    }
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("Image"), value: "image", align: "left" },
        { text: this.$t("Each"), value: "each", align: "center" },
        {
          text: this.$t("Quantity"),
          value: "quantity",
          align: "center",
          width: "20%"
        },
        { text: this.$t("Sub Total"), value: "subtotal", align: "center" },
        { text: this.$t("Remove"), value: "actions", align: "center" }
      ];
    },
    suburbRules() {
      const arr = [v => !!v || this.$t("Suburb/City is required")];
      return arr;
    },
    addressRules() {
      const arr = [v => !!v || this.$t("Address is required")];
      return arr;
    },
    stateRules() {
      const arr = [v => !!v || this.$t("State/Province is required")];
      return arr;
    },
    postcodeRules() {
      const arr = [v => !!v || this.$t("Postcode/Zip is required")];
      return arr;
    },
    countryRules() {
      const arr = [v => !!v || this.$t("Country/Region")];
      return arr;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    currentProgramAdmins() {
      const arr = this.$store.getters.currentProgram.admins;
      return arr.map(el => el.adminEmail);
    },
    processingOrder() {
      return this.$store.getters.processingOrder;
    },
    cart: {
      get: function() {
        return this.$store.getters.cart;
      },
      set: function(newVal) {
        this.$store.state.cart = newVal;
      }
    },
    buttonText() {
      if (this.stage === 3) {
        const val = this.cartValue;
        return (
          this.$t("Submit order of") +
          " " +
          this.formatPoints(val) +
          " " +
          this.$t("POINTS")
        );
      } else {
        return this.$t("NEXT");
      }
    },
    cartValue() {
      const arr = this.cartTableDetail;
      return arr.reduce((n, { subtotal }) => n + subtotal, 0);
    },
    checkoutBlock() {
      if (this.cartValue === 0) {
        return true;
      } else if (this.currentUser.balance >= this.cartValue) {
        return false;
      }
      return true;
    },
    addressFilled() {
      if (this.stage === 2) {
        if (
          !this.address ||
          !this.postcode ||
          !this.suburb ||
          !this.selectedRegion ||
          !this.selectedCountry
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    rewards() {
      return this.$store.getters.rewards;
    },
    cartTableDetail() {
      const ids = this.cart;
      const arr = this.rewards;
      const result = [];
      ids.forEach((el, idx) => {
        const item = arr.find(i => i.id === el.id);
        var sub = el.quantity * item.points;
        var itemId = nanoid();
        var memberId = this.currentUser._id;
        const temp = {
          ...item,
          idx: idx,
          itemId: itemId,
          quantity: el.quantity,
          each: item.points,
          subtotal: sub,
          status: "Open",
          memberId: memberId
        };
        result.push(temp);
      });
      return result;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingPointsTable() {
      return this.$store.getters.loadingPointsTable;
    },
    arrBalance() {
      const arr = this.cart;
      return arr.reduce((n, { points }) => n + points, 0);
    },
    variance() {
      const transBalance = this.transactions.reduce(
        (n, { points }) => n + points,
        0
      );
      return this.currentMemberPoints.balance - transBalance;
    },
    currentMemberPoints() {
      return this.currentUser;
    },
    addressFormDisabled() {
      if (this.selectedCountry) {
        return false;
      }
      return true;
    },
    countryList() {
      const shortlist = this.countryShortlist;
      const arr = this.countries;
      const list = arr.map(el => el.countryName);
      if (this.countryShortlist.length >= 1) {
        return [...shortlist, "___________________", ...list];
      }
      return list;
    },
    regionList() {
      const selected = this.selectedCountry;
      const countries = this.countries;
      if (selected) {
        const result = countries.find(el => el.countryName === selected);
        return result.regions.map(el => el.name);
      } else {
        return [];
      }
    },
    cssVars() {
      return {
        "--header-text-color": this.component.cart.content.heading.color,
        "--header-text-size": this.component.cart.content.heading.size + "px",
        "--header-text-style": this.component.cart.content.heading.style,
        "--header-text-weight": this.component.cart.content.heading.weight,
        "--header-line-height": this.component.cart.content.heading.lineHeight,
        "--header-font": this.component.cart.content.heading.font,
        "--row-text-color": this.component.cart.content.data.color,
        "--row-text-size": this.component.cart.content.data.size + "px",
        "--row-text-style": this.component.cart.content.data.style,
        "--row-text-weight": this.component.cart.content.data.weight,
        "--row-line-height": this.component.cart.content.data.lineHeight,
        "--row-font": this.component.cart.content.data.font,
        "--text-align": this.component.cart.content.align,
        "--row-border-bottom": this.rowBorders,
        "--row-hover-color": this.component.cart.rowHoverColor.hexa,
        "--current-page-color": this.component.cart.title.color
      };
    },
    cssVarsTransactions() {
      return {
        "--header-text-color": this.component.cart.content.heading.color,
        "--header-text-size": this.component.cart.content.heading.size + "px",
        "--header-text-style": this.component.cart.content.heading.style,
        "--header-text-weight": this.component.cart.content.heading.weight,
        "--header-line-height": this.component.cart.content.heading.lineHeight,
        "--header-font": this.component.cart.content.heading.font,
        "--row-text-color": this.component.cart.content.data.color,
        "--row-text-size": this.component.cart.content.data.size + "px",
        "--row-text-style": this.component.cart.content.data.style,
        "--row-text-weight": this.component.cart.content.data.weight,
        "--row-line-height": this.component.cart.content.data.lineHeight,
        "--row-font": this.component.cart.content.data.font,
        "--text-align": "left",
        "--row-border-bottom": this.rowBorders,
        "--row-hover-color": this.component.cart.rowHoverColor.hexa,
        "--current-page-color": this.component.cart.title.color
      };
    },
    classes() {
      if (this.component.cart.fullscreen === true) return "rounded-0";
      return this.component.cart.rounded;
    },
    rowBorders() {
      return (
        this.component.cart.rowBorderSize +
        "px" +
        " solid " +
        this.component.cart.rowBorderColor.hexa
      );
    },
    iconSize() {
      return this.component.cart.iconSize;
    },
    gradient() {
      if (this.component.cart.color.type === "gradient") {
        var color = this.component.cart.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return "background: " + this.component.cart.color.solidColor.hexa + ";";
      }
    },
    styles() {
      var styles =
        "padding-bottom: " +
        this.component.cart.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.cart.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.cart.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.cart.padding.right +
        "px ;" +
        "border: " +
        this.component.cart.borderSize +
        "px solid " +
        ";";
      this.component.cart.borderColor.hexa + "; ";
      return styles;
    },
    detailTableStyles() {
      var styles =
        "margin-bottom: " +
        this.component.cart.detail.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.cart.detail.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.cart.detail.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.cart.detail.margin.right +
        "px ;";
      return styles;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    handleClose() {
      this.clear();
      this.$emit("onClose");
      this.stage = 1;
      this.$store.dispatch("setProcessingOrder", false);
      this.orderSuccess = false;
    },
    clear() {
      this.$store.commit("setTransactions", []);
    },
    addToCart(id) {
      this.$store.dispatch("addToCart", id);
    },
    clearCart() {
      this.$store.dispatch("clearCart");
    },
    handleDelete(item) {
      const index = item.idx;
      this.cart.splice(index, 1);
    },
    formatPoints(val) {
      return numeral(val).format("0,0");
    },
    plus(item) {
      const index = item.idx;
      if (this.cart[index].quantity === isNaN) {
        this.cart[index].quantity = parseInt(1, 0);
      }
      this.cart[index].quantity = parseInt(this.cart[index].quantity, 0) + 1;
    },
    minus(item) {
      const index = item.idx;
      if (this.cart[index].quantity > 1) {
        this.cart[index].quantity = parseInt(this.cart[index].quantity, 0) - 1;
      }
    },
    async nextStep() {
      if (this.stage === 2) {
        console.log("stage 2");
        await this.validate();
        if (this.valid) {
          this.stage++;
        }
      } else if (this.stage === 3) {
        console.log("submit.....");
        this.handleOrder();
      } else {
        this.stage++;
      }
    },
    backStep() {
      this.$store.dispatch("setProcessingOrder", false);
      this.stage--;
    },
    handlePage(page) {
      this.page = page;
    },
    paginationStyle(val) {
      if (val === this.page) return "incentable-current-page";
      return "incentable-pagination";
    },
    resetPage() {
      this.page = 1;
      this.clearDatabucketData();
    },
    async handleOrder() {
      console.log("processing order.....");
      this.$store.dispatch("setProcessingOrder", true);
      let orderSummary = [];
      this.cartTableDetail.forEach(el => {
        orderSummary.push(
          "\n" +
            el.quantity +
            " x " +
            el.title +
            " " +
            "(" +
            this.formatPoints(el.subtotal) +
            " points)"
        );
      });
      var imageUrl = DEFAULT_BANNER_URL;
      imageUrl = this.currentProgram.notificationSettings.bannerUrl;
      const orderSummaryString = orderSummary;
      const orderSummaryFormatted = orderSummaryString.toString().replace();
      const date = new Date();
      const updatedItems = this.cartTableDetail.map(el => ({
        ...el,
        created: date,
        updated: date
      }));

      const order = {
        items: [...updatedItems],
        status: "Open",
        member: this.currentUser,
        toEmails: [...this.currentProgramAdmins, this.currentUser.email],
        subject: "Order Confirmation",
        name: this.currentUser.firstname + " " + this.currentUser.lastname,
        body: orderSummaryFormatted,
        sender: this.currentProgram.displayTitle,
        imageUrl: imageUrl,
        replyEmail: REPLY_TO_EMAIL,
        address: this.address,
        postcode: this.postcode,
        state: this.selectedRegion,
        country: this.selectedCountry,
        suburb: this.suburb,
        email: this.currentUser.email,
        total: numeral(this.cartValue).format("0,0"),
        cartValue: this.cartValue,
        date: this.$options.filters.date(new Date()),
        type: "Redeem"
      };
      console.log(order);
      await this.$store
        .dispatch("createOrder", order)
        .then((this.orderSuccess = true), this.clearCart());
    }
  }
};
</script>

<style scoped>
.sub-heading-shopping-cart {
  font-size: 12px;
}
.centered-input >>> input {
  text-align: center;
}
.stepper-style {
  background-color: transparent !important;
}
.incentable-pagination {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
}
.incentable-current-page {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--current-page-color) !important;
}
</style>
